@charset "UTF-8";

// 1カラムでメインのコンテンツ幅に納める
.l-column {
  margin: 0 auto;
  padding: 0 0;
  max-width: 100%;
  @include mq-tb {
    max-width: $base-content-width;
  }
}

// 横並びラッパー
.l-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}


// 2カラム構成ののラッパー
.l-container {
  @include mq-tb {
  display: flex;
  justify-content: space-between;
  flex: 1 1 $base-content-width;
  flex-wrap: nowrap;
  margin: 0 auto;
  max-width: $base-content-width;
  }
}

.l-main {
  width: 100%;
  margin: 0 0;
  padding: 0 0;
  text-align: center;
  @include mq-tb {
    flex: 1 1 auto;
    order: 2;
  }
}

.l-sub {
  //display: none;

  @include mq-tb {
    display: block;
    flex: 0 1 25%;
    order: 1;
    margin: 0 30px 0 0;
    padding: 20px 0;
    min-width: 260px;
  }
}

// 画面幅のコンテンツ幅に対して1/3の幅にする 適用なし？削除してもいいか調べる
.l-tripartitionColumn {
  flex: 1 1 100%;
  max-width: none;
  margin: 0;
  @include mq-tb {
    flex: 1 0 0px;
    max-width: ($base-content-width - $column-space*(2/3))/3;
    margin-right: $column-space*2/3;
  }
  &:last-child {
    margin-right: 0;
  }
}

// l-メインのコンテンツ幅に対して1/3の幅にする
//　暫定なので修正必要（ＩＮＶＩＴＡＴＩＯＮ－ＴＯＰ）
.l-three {
  flex: 1 1 100%;
  max-width: 50%;
  padding-right: 15px;
  align-self: flex-end;
  @include mq-tb {  //暫定内容不明のためただ書き写ししただけ
    flex: 1 1 100%;
    max-width: 33%;
  }
}

.l-half {
  & .l-three {
    max-width: 50%;
  }
}
