@charset "UTF-8";

.breadcrumb {
  padding-left: 0px;
  margin-bottom: 10px;
  @include mq-tb {
    padding-left: 14px;
  }
  &_main {
    font-size: 12px;
    @include mq-tb {
      font-size: 16px;
    }
    & a {
      text-decoration: none;
      padding: 6px 0 6px 6px;
      @include mq-tb {
        padding: 8px 10px 8px;
      }
    }
    & li {
      display: inline-block;
    }
    & li:after {
      content: "≫";
      padding: 0 5px;
    }
    & li:last-child::after {
      content: none;
    }
  }
  &_homeicon {
    font-size: 24px;
  }
}
