@charset "UTF-8";

.buttom-right {
  float: right;
  padding: 0 26px 0 0;
}

a.bt-next{
  padding-top: 10px;
  font-size: 14px;
  position: relative;
  display: block;
  height: 30px;
  width: 120px;
  text-decoration: none;
  background: $color-pink;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  box-shadow: 1px 2px 4px #d4842c;
  @include mq-tb {
    font-size: 22px;
    width: 200px;
    height: 44px;
  }
}
a.bt-next:active{
  -ms-transform: translateY(2px);
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  box-shadow:none;
}

.commonSubmit {
  display: block;
  width: 220px;
  padding: 1em 1em;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  color: #222;
  background: linear-gradient(to bottom,#FFE7AD,#F3C34D);
  border: 1px solid #A0802F;
  border-radius: 6px;
}
