@charset "UTF-8";
.red {
  color: red;
}

.l-column {
  margin: 0 auto;
  padding: 0 0;
  max-width: 100%;
}

@media print, screen and (min-width: 751px) {
  .l-column {
    max-width: 1024px;
  }
}

.l-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}

@media print, screen and (min-width: 751px) {
  .l-container {
    display: flex;
    justify-content: space-between;
    flex: 1 1 1024px;
    flex-wrap: nowrap;
    margin: 0 auto;
    max-width: 1024px;
  }
}

.l-main {
  width: 100%;
  margin: 0 0;
  padding: 0 0;
  text-align: center;
}

@media print, screen and (min-width: 751px) {
  .l-main {
    flex: 1 1 auto;
    order: 2;
  }
}

@media print, screen and (min-width: 751px) {
  .l-sub {
    display: block;
    flex: 0 1 25%;
    order: 1;
    margin: 0 30px 0 0;
    padding: 20px 0;
    min-width: 260px;
  }
}

.l-tripartitionColumn {
  flex: 1 1 100%;
  max-width: none;
  margin: 0;
}

@media print, screen and (min-width: 751px) {
  .l-tripartitionColumn {
    flex: 1 0 0px;
    max-width: 332.44444px;
    margin-right: 26.66667px;
  }
}

.l-tripartitionColumn:last-child {
  margin-right: 0;
}

.l-three {
  flex: 1 1 100%;
  max-width: 50%;
  padding-right: 15px;
  align-self: flex-end;
}

@media print, screen and (min-width: 751px) {
  .l-three {
    flex: 1 1 100%;
    max-width: 33%;
  }
}

.l-half .l-three {
  max-width: 50%;
}

body {
  font-family: "メイリオ", "Meiryo", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif;
}

.obon {
  background: #eeeeee;
  padding: 20px;
}

.obon_red {
  color: red;
  margin-bottom: 4px;
}

.obon_head {
  font-size: 18px;
  color: red;
  margin-bottom: 15px;
}

.obon_mr13 {
  margin-bottom: 13px;
}

.wrapper {
  border-top: 14px solid #FAEFEE;
  padding-top: 0px;
}

@media print, screen and (min-width: 751px) {
  .wrapper {
    padding-top: 10px;
  }
}

.header {
  margin-bottom: 4px;
}

.header_main {
  width: 450px;
  margin-top: 20px;
  background: url("../img/logo.png") no-repeat;
  background-size: 80%;
  background-size: contain;
  margin-bottom: 3px;
}

@media print, screen and (min-width: 751px) {
  .header_main {
    background-size: 100%;
    margin-bottom: 0;
  }
}

.header_mainText {
  width: 190px;
  padding: 5px 5px 20px 5px;
  margin-top: -10px;
  margin-left: auto;
  margin-right: 20px;
  font-size: 12px;
  line-height: 1.0;
}

@media print, screen and (min-width: 751px) {
  .header_mainText {
    width: 250px;
    padding: 5px 0 10px 5px;
    margin-left: auto;
    margin-right: 0;
    font-size: 12px;
    line-height: 1.4;
  }
}

.header_head {
  margin-bottom: 6px;
  font-weight: bold;
}

.header_head span {
  font-size: 16px;
  color: red;
}

@media print, screen and (min-width: 751px) {
  .header_head span {
    font-size: 20px;
  }
}

.header_sub {
  flex: 1 1 45%;
  text-align: center;
  line-height: 1.2;
}

.header .menu_item {
  padding: 0;
}

.header_note {
  margin-top: 6px;
  text-align: right;
  font-size: 12px;
}

@media print, screen and (min-width: 751px) {
  .header_note {
    font-size: 16px;
  }
}

.header_note span {
  color: red;
}

.header_menu {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}

.header_menu li:nth-child(2) {
  display: none;
}

.header_menu li {
  width: 33%;
}

@media print, screen and (min-width: 751px) {
  .header_menu {
    font-size: 16px;
  }
  .header_menu li:nth-child(2), .header_menu li:nth-child(3) {
    display: block;
  }
}

.header_menu_item {
  padding: 5px 10px;
}

@media print, screen and (min-width: 751px) {
  .header_menu_item {
    padding: 5px 5px;
  }
}

.gnav {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}

@media print, screen and (min-width: 751px) {
  .gnav {
    font-size: 16px;
    margin-bottom: 14px;
  }
}

.gnav_menu {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex: 1 1 50%;
}

@media print, screen and (min-width: 751px) {
  .gnav_menu {
    flex-wrap: nowrap;
    flex: 0 1 auto;
  }
}

.gnav_menu_item {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
}

.gnav li {
  width: 49%;
  margin-bottom: 5px;
}

@media print, screen and (min-width: 751px) {
  .gnav li {
    width: 160px;
  }
}

.gnav_top {
  background: url("../img/gnav1.png") no-repeat;
  background-size: cover;
}

.gnav_invitation {
  background: url("../img/gnav2.png") no-repeat;
  background-size: cover;
}

.gnav_reception {
  background: url("../img/gnav3.png") no-repeat;
  background-size: cover;
}

.gnav_seatplace {
  background: url("../img/gnav4.png") no-repeat;
  background-size: cover;
}

.gnav_poti {
  background: url("../img/gnav5.png") no-repeat;
  background-size: cover;
}

.gnav_spare {
  background: url("../img/gnav6.png") no-repeat;
  background-size: cover;
}

.gnav li {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.hero {
  margin-bottom: 5px;
}

.hero img {
  width: 100%;
  height: auto;
}

.breadcrumb {
  padding-left: 0px;
  margin-bottom: 10px;
}

@media print, screen and (min-width: 751px) {
  .breadcrumb {
    padding-left: 14px;
  }
}

.breadcrumb_main {
  font-size: 12px;
}

@media print, screen and (min-width: 751px) {
  .breadcrumb_main {
    font-size: 16px;
  }
}

.breadcrumb_main a {
  text-decoration: none;
  padding: 6px 0 6px 6px;
}

@media print, screen and (min-width: 751px) {
  .breadcrumb_main a {
    padding: 8px 10px 8px;
  }
}

.breadcrumb_main li {
  display: inline-block;
}

.breadcrumb_main li:after {
  content: "≫";
  padding: 0 5px;
}

.breadcrumb_main li:last-child::after {
  content: none;
}

.breadcrumb_homeicon {
  font-size: 24px;
}

.subnav {
  text-align: center;
  margin-bottom: 10px;
}

@media print, screen and (min-width: 751px) {
  .subnav {
    margin-bottom: 30px;
  }
}

.subnav_head {
  width: 260px;
  height: 75px;
  margin-bottom: 12px;
}

.subnav_bg-pickup {
  background: url("../img/sidenav-bg-pickup.png") no-repeat left center;
  background-size: cover;
}

.subnav_bg-category {
  background: url("../img/sidenav-bg-category.png") no-repeat left center;
  background-size: cover;
}

.subnav_bg-freeitem {
  background: url("../img/sidenav-bg-freesample.png") no-repeat left center;
  background-size: cover;
}

.subnav_bg-guidebook {
  background: url("../img/sidenav-bg-guidebook.png") no-repeat left center;
  background-size: cover;
}

.subnav_bg-infomation {
  background: url("../img/sidenav-bg-infomation.png") no-repeat left center;
  background-size: cover;
}

.subnav_headText {
  width: 200px;
  padding: 6px 0 10px 20px;
  margin-left: auto;
  font-size: 24px;
  line-height: 1.2;
  color: #FFACA4;
}

.subnav_headText span {
  font-size: 18px;
  color: #333333;
  font-weight: normal;
}

.subnav_headText-free {
  padding: 4px 0 10px 30px;
  font-size: 24px;
  line-height: 0.9;
}

.subnav_headText-free span {
  font-size: 16px;
  line-height: 1;
}

.subnav_item {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

@media print, screen and (min-width: 751px) {
  .subnav_item {
    margin-bottom: 20px;
  }
}

.subnav_item-hand {
  background: url(../img/pinkcircle.png) no-repeat left 2px center;
  margin-bottom: 12px;
}

@media print, screen and (min-width: 751px) {
  .subnav_item-hand {
    background: url(../img/pinkcircle.png) no-repeat left 7px center;
    margin-bottom: 15px;
  }
}

.subnav_item-print {
  background: url(../img/greencircle.png) no-repeat left 2px center;
  margin-bottom: 15px;
}

@media print, screen and (min-width: 751px) {
  .subnav_item-print {
    background: url(../img/greencircle.png) no-repeat left 7px center;
    margin-bottom: 20px;
  }
}

.subnav_item p {
  font-size: 15px;
  text-align: left;
  padding-left: 18px;
}

@media print, screen and (min-width: 751px) {
  .subnav_item p {
    font-size: 22px;
    padding-left: 25px;
  }
}

.subnav:nth-child(3) .subnav_item {
  padding: 18px 10px 0;
}

.subnav_itemWrap {
  display: flex;
  flex-wrap: wrap;
}

@media print, screen and (min-width: 751px) {
  .subnav_itemWrap {
    display: block;
  }
}

.l-sub section:nth-child(1) .subnav_item, .l-sub section:nth-child(2) .subnav_item, .l-sub section:nth-child(3) .subnav_item, .l-sub section:nth-child(5) .subnav_item {
  padding: 0 5px 0 5px;
  width: 49%;
}

@media print, screen and (min-width: 751px) {
  .l-sub section:nth-child(1) .subnav_item, .l-sub section:nth-child(2) .subnav_item, .l-sub section:nth-child(3) .subnav_item, .l-sub section:nth-child(5) .subnav_item {
    width: auto;
    padding: 0;
  }
}

.l-sub section:nth-child(4) .subnav_itemWrap .subnav_item {
  padding: 0 5px 0 5px;
  width: 49%;
}

@media print, screen and (min-width: 751px) {
  .l-sub section:nth-child(4) .subnav_itemWrap .subnav_item {
    width: auto;
    padding: 0;
  }
}

.l-sub section:nth-child(1) img, .l-sub section:nth-child(2) img, .l-sub section:nth-child(3) img {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 7px;
}

@media print, screen and (min-width: 751px) {
  .l-sub section:nth-child(1) img, .l-sub section:nth-child(2) img, .l-sub section:nth-child(3) img {
    margin-bottom: 15px;
  }
}

.l-sub section:nth-child(4) img, .l-sub section:nth-child(5) img {
  width: 100%;
  border-radius: 7px;
}

.infomation a {
  display: block;
}

.infomation .subnav_item p {
  margin-bottom: 10px;
}

.infomation_head {
  font-weight: normal;
  font-size: 20px;
  color: #FFACA4;
  margin-bottom: 12px;
}

.infomation-border {
  border: 1px solid #333333;
  border-radius: 10px;
  background: url("../img/border.png") no-repeat left 6px center;
}

.infomation .subnav_item span {
  font-size: 24px;
  color: red;
}

.infomation_faqText {
  font-size: 26px;
}

.shopguide {
  padding: 20px 20px 0;
  background-color: #FAEFEE;
}

@media print, screen and (min-width: 751px) {
  .shopguide_main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.shopguide_mainHead {
  font-size: 20px;
  padding: 10px 0 0 10px;
  margin-bottom: 10px;
  border-left: 6px solid #FFACA4;
}

@media print, screen and (min-width: 751px) {
  .shopguide_mainHead {
    font-size: 26px;
    margin-bottom: 0;
  }
}

.shopguide .shopguide_buttom {
  padding-top: 12px;
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
  height: 40px;
  width: 100%;
  text-decoration: none;
  background: #FFACA4;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  box-shadow: 1px 2px 4px #d4842c;
}

@media print, screen and (min-width: 751px) {
  .shopguide .shopguide_buttom {
    font-size: 20px;
    width: 320px;
    height: 40px;
  }
}

.shopguide_body {
  line-height: 1.3;
}

@media print, screen and (min-width: 751px) {
  .shopguide_body {
    line-height: 1.6;
  }
}

.shopguide_body h3 {
  font-size: 16px;
  margin-bottom: 6px;
  border-bottom: 3px solid #9FA0A0;
}

.shopguide_body h4 {
  font-size: 13px;
  padding-left: 14px;
  background: url("../img/kuromaru.png") no-repeat left center;
}

.shopguide_body p {
  font-size: 13px;
  margin-bottom: 10px;
}

@media print, screen and (min-width: 751px) {
  .shopguide_body p {
    font-size: 16px;
  }
}

.shopguide_padding {
  padding-left: 12px;
}

.shopguide_padding p {
  padding-left: 14px;
}

.shopguide-mb60px {
  margin-bottom: 20px;
}

@media print, screen and (min-width: 751px) {
  .shopguide-mb60px {
    margin-bottom: 60px;
  }
}

.shopguide_img-credit {
  width: 100%;
  height: auto;
  max-width: 210px;
}

.shopguide_img-time {
  width: 100%;
  height: auto;
  max-width: 310px;
}

.shopguide_img {
  width: 100%;
  height: auto;
  padding: 6px;
}

.shopguide_body div:nth-of-type(3) p:nth-of-type(1) {
  margin-bottom: 0;
}

.shopguide_table {
  width: 100%;
  margin-bottom: 20px;
}

.shopguide_table td {
  text-align: center;
  vertical-align: middle;
  padding: 6px 6px;
  font-size: 15px;
}

.shopguide_table td span {
  font-size: 18px;
}

@media print, screen and (min-width: 751px) {
  .shopguide_table td {
    font-size: 16px;
  }
}

.footer_img {
  width: 100%;
  height: auto;
}

.footer_footBody {
  padding-bottom: 10px;
}

@media print, screen and (min-width: 751px) {
  .footer_footBody {
    padding-top: 5px;
  }
}

.footer_menu {
  display: inline;
  font-size: 10px;
  padding: 0px 5px;
  overflow: hidden;
}

@media print, screen and (min-width: 751px) {
  .footer_menu {
    display: flex;
    justify-content: space-around;
    font-size: 17px;
    padding: 5px 30px;
  }
}

.footer_menu_item {
  display: block;
  padding: 5px 5px;
  float: left;
  width: 33%;
  text-decoration: none;
}

@media print, screen and (min-width: 751px) {
  .footer_menu_item {
    padding: 5px 10px;
    width: auto;
  }
}

.footer_footFoot {
  text-align: center;
  padding: 12px 0;
  margin-top: 10px;
  background-color: #FAEFEE;
  font-size: 12px;
}

@media print, screen and (min-width: 751px) {
  .footer_footFoot {
    margin-top: 0;
    font-size: 15px;
  }
}

.point {
  display: flex;
  padding: 0 0 0 0;
  margin-bottom: 10px;
  justify-content: center;
}

@media print, screen and (min-width: 751px) {
  .point {
    padding: 0 50px 0 10px;
    margin-bottom: 20px;
  }
}

.point_sub {
  margin: 0 15px 0 0;
  align-self: flex-start;
  width: 40px;
  height: 50px;
}

@media print, screen and (min-width: 751px) {
  .point_sub {
    margin: 0 40px 0 0;
    width: 60px;
    height: 70px;
  }
}

.point_main {
  padding: 0 0 0 0;
  text-align: left;
}

.point_head {
  text-align: left;
  font-size: 20px;
  margin: 0 0 10px 0;
  font-weight: normal;
}

@media print, screen and (min-width: 751px) {
  .point_head {
    font-size: 35px;
  }
}

.point_body {
  font-size: 14px;
}

@media print, screen and (min-width: 751px) {
  .point_body {
    font-size: 20px;
  }
}

.point_foot {
  font-size: 14px;
  text-align: left;
  line-height: 1.4;
  width: 100%;
  padding: 0 10px 0 10px;
  margin-bottom: 8px;
}

@media print, screen and (min-width: 751px) {
  .point_foot {
    font-size: 20px;
    padding: 0 20px 0 90px;
    width: 88%;
    margin-bottom: 12px;
  }
}

.separate-line {
  margin-bottom: 15px;
  width: 80%;
}

@media print, screen and (min-width: 751px) {
  .separate-line {
    margin-bottom: 40px;
  }
}

.buttom-right {
  float: right;
  padding: 0 26px 0 0;
}

a.bt-next {
  padding-top: 10px;
  font-size: 14px;
  position: relative;
  display: block;
  height: 30px;
  width: 120px;
  text-decoration: none;
  background: #FFACA4;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  box-shadow: 1px 2px 4px #d4842c;
}

@media print, screen and (min-width: 751px) {
  a.bt-next {
    font-size: 22px;
    width: 200px;
    height: 44px;
  }
}

a.bt-next:active {
  -ms-transform: translateY(2px);
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
  box-shadow: none;
}

.commonSubmit {
  display: block;
  width: 220px;
  padding: 1em 1em;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  color: #222;
  background: linear-gradient(to bottom, #FFE7AD, #F3C34D);
  border: 1px solid #A0802F;
  border-radius: 6px;
}

.underline {
  background: linear-gradient(transparent 70%, #fafa00 60%, #fafa00 100%);
}
