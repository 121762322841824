@charset "UTF-8";

.subnav {
  text-align: center;
  margin-bottom: 10px;
  @include mq-tb {
    margin-bottom: 30px;
  }
  &_head {
    width: 260px;
    height: 75px;
    margin-bottom: 12px;
  }
  &_bg-pickup {
    background: url("../img/sidenav-bg-pickup.png") no-repeat left center;
    background-size: cover;
  }
  &_bg-category {
    background: url("../img/sidenav-bg-category.png") no-repeat left center;
    background-size: cover;
  }
  &_bg-freeitem {
    background: url("../img/sidenav-bg-freesample.png") no-repeat left center;
    background-size: cover;
  }
  &_bg-guidebook {
    background: url("../img/sidenav-bg-guidebook.png") no-repeat left center;
    background-size: cover;
  }
  &_bg-infomation {
    background: url("../img/sidenav-bg-infomation.png") no-repeat left center;
    background-size: cover;
  }
  &_headText {
    width: 200px;
    padding: 6px 0 10px 20px;
    margin-left: auto;
    font-size: 24px;
    line-height: 1.2;
    color: $color-pink;
    & span {
      font-size: 18px;
      color: $color-main-text;
      font-weight: normal;
    }
  }
  &_headText-free {
    padding: 4px 0 10px 30px;
    font-size: 24px;
    line-height: 0.9;
    & span {
      font-size: 16px;
      line-height: 1;
    }
  }
  &_item {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    @include mq-tb {
      margin-bottom: 20px;
    }
    &-hand {
      background: url(../img/pinkcircle.png) no-repeat left 2px center;
      margin-bottom: 12px;
      @include mq-tb {
        background: url(../img/pinkcircle.png) no-repeat left 7px center;
        margin-bottom: 15px;
      }
    }
    &-print {
      background: url(../img/greencircle.png) no-repeat left 2px center;
      margin-bottom: 15px;
      @include mq-tb {
        background: url(../img/greencircle.png) no-repeat left 7px center;
        margin-bottom: 20px;
      }
    }
    & p {
      font-size: 15px;
      text-align: left;
      padding-left: 18px;
      @include mq-tb {
        font-size: 22px;
        padding-left: 25px;
      }
    }
  }
  &:nth-child(3) &_item {
    padding: 18px 10px 0;
  }
}

//.l-sub section:nth-child(1) {
//  display: none;
//  @include mq-tb {
//    display: block;
//  }
//}

.subnav_itemWrap {
  display: flex;
  flex-wrap: wrap;
  @include mq-tb {
    display: block;
  }
}

.l-sub section:nth-child(1) .subnav_item, .l-sub section:nth-child(2) .subnav_item, .l-sub section:nth-child(3) .subnav_item, .l-sub section:nth-child(5) .subnav_item {
  padding: 0 5px 0 5px;
  width: 49%;
  @include mq-tb {
    width: auto;
    padding: 0;
  }
}

.l-sub section:nth-child(4) .subnav_itemWrap .subnav_item {
  padding: 0 5px 0 5px;
  width: 49%;
  @include mq-tb {
    width: auto;
    padding: 0;
  }
}

//.l-sub section:nth-child(1) img {
//  border-radius: 7px;
//  @include mq-tb {
//  margin-bottom: 15px;
//  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
//  width: 100%;
//  }
//}

.l-sub section:nth-child(1) img, .l-sub section:nth-child(2) img, .l-sub section:nth-child(3) img {
  //  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 7px;
  @include mq-tb {
  margin-bottom: 15px;
  }
}

.l-sub section:nth-child(4) img, .l-sub section:nth-child(5) img {
  width: 100%;
  border-radius: 7px;
}


.infomation {
  & a {
    display: block;
  }
  & .subnav_item {
    & p {
      margin-bottom: 10px;
    }
  }
  &_head {
    font-weight: normal;
    font-size: 20px;
    color: $color-pink;
    margin-bottom: 12px;
  }
  &-border {
  border:1px solid #333333;
  border-radius: 10px;
  background: url("../img/border.png") no-repeat left 6px center;
  }
  & .subnav_item span {
    font-size: 24px;
    color: red;
  }
  &_faqText {
    font-size: 26px;
  }
}
