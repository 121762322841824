@charset "UTF-8";

.footer {
}

.shopguide {
  padding: 20px 20px 0;
  background-color: #FAEFEE;
  &_main {
    @include mq-tb {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
  &_mainHead {
    font-size: 20px;
    padding: 10px 0 0 10px;
    margin-bottom: 10px;
    border-left: 6px solid $color-pink;
    @include mq-tb {
      font-size: 26px;
      margin-bottom: 0;
    }
  }
  & &_buttom {
    padding-top: 12px;
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
    height: 40px;
    width: 100%;
    text-decoration: none;
    background: $color-pink;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    box-shadow: 1px 2px 4px #d4842c;
    @include mq-tb {
      font-size: 20px;
      width: 320px;
      height: 40px;
    }
  }
  &_body {
    line-height: 1.3;
    @include mq-tb {
      line-height: 1.6;
    }
  }
  &_body h3 {
    font-size: 16px;
    margin-bottom: 6px;
    border-bottom: 3px solid #9FA0A0;
  }
  &_body h4 {
    font-size: 13px;
    padding-left: 14px;
    background: url("../img/kuromaru.png") no-repeat left center;
  }
  &_body p {
    font-size: 13px;
    margin-bottom: 10px;
    @include mq-tb {
      font-size: 16px;
    }
  }
  &_padding {
    padding-left: 12px;
  }
  &_padding p {
    padding-left: 14px;
  }
  &-mb60px {
    margin-bottom: 20px;
    @include mq-tb {
      margin-bottom: 60px;
    }
  }
  &_img-credit {
    width: 100%;
    height: auto;
    max-width: 210px;
  }
  &_img-time {
    width: 100%;
    height: auto;
    max-width: 310px;
  }
  &_img {
    width: 100%;
    height: auto;
    padding: 6px;
  }
  &_body div:nth-of-type(3) p:nth-of-type(1) {
    margin-bottom: 0;
  }
  &_table {
    width: 100%;
    margin-bottom: 20px;
  }
  &_table td {
    text-align:center; vertical-align:middle;
    padding: 6px 6px;
    font-size: 15px;
    & span {
      font-size: 18px;
    }
    @include mq-tb {
      font-size: 16px;
    }
  }
}

.footer_imgbox {
}

.footer_img {
  width: 100%;
  height: auto;
}

.footer_footBody {
  padding-bottom: 10px;
  @include mq-tb {
    padding-top: 5px;
  }
}

.footer_menu {
  display: inline;
  font-size: 10px;
  padding: 0px 5px;
  overflow: hidden;
    @include mq-tb {
      display: flex;
      justify-content: space-around;
      font-size: 17px;
      padding: 5px 30px;
  }
  &_item {
    display: block;
    padding: 5px 5px;
    float: left;
    width: 33%;
    text-decoration: none;
    @include mq-tb {
      padding: 5px 10px;
      width: auto;
    }
  }
}

.footer_footFoot{
  text-align: center;
  padding: 12px 0;
  margin-top: 10px;
  background-color: #FAEFEE;
  font-size: 12px;
  @include mq-tb {
    margin-top: 0;
    font-size: 15px;
  }
}
