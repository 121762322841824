@charset "UTF-8";

.point {
  display: flex;
  padding: 0 0 0 0;
  margin-bottom: 10px;
  justify-content: center;
  @include mq-tb {
    padding: 0 50px 0 10px;
    margin-bottom: 20px;
  }
  &_sub {
    margin: 0 15px 0 0;
    align-self: flex-start;
    width: 40px;
    height: 50px;
    @include mq-tb {
      margin: 0 40px 0 0;
      width: 60px;
      height: 70px;
    }
  }
  &_main {
    padding: 0 0 0 0;
    text-align: left;
  }
  &_head {
    text-align: left;
    font-size: 20px;
    margin: 0 0 10px 0;
    font-weight: normal;
    @include mq-tb {
      font-size: 35px;
    }
  }
  &_body {
    font-size: 14px;
    @include mq-tb {
      font-size: 20px;
    }
  }
  &_foot {
    font-size: 14px;
    text-align: left;
    line-height: 1.4;
    width: 100%;
    padding: 0 10px 0 10px;
    margin-bottom: 8px;
    @include mq-tb {
      font-size: 20px;
      padding: 0 20px 0 90px;
      width: 88%;
      margin-bottom: 12px;
    }
  }
}
