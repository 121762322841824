@charset "UTF-8";


.hero {
//  position: relative;
//  overflow: hidden;
//  height: 530px;
  margin-bottom: 5px;
//  & ul {
//    position: absolute;
//    width: 3072px;
//    @include mq-sp {
//      width: 100%;
//    }
//  }
//  & li {
//    float: left;
//    width: 1024px;
//    @include mq-sp {
//      width: 100%;
//    }
//  }
  & img {
    width: 100%;
    height: auto;
  }
//  &_prevBtn {
//    position: absolute;
//    top: 225px;
//    left: 20px;
//  }
//  &_nextBtn {
//    position: absolute;
//    top: 225px;
//    right: 20px;
//  }
}
