@charset "UTF-8";

.gnav {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  @include mq-tb {
    font-size: 16px;
    margin-bottom: 14px;
  }
  &_menu {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex: 1 1 50%;
    @include mq-tb {
      flex-wrap: nowrap;
      flex: 0 1 auto;
    }
    &_item {
      display: block;
      padding: 10px 15px;
      text-decoration: none;
    }
  }
  & li {
    width: 49%;
    margin-bottom: 5px;
    @include mq-tb {
      width: 160px;
    }
    }
  &_top {
    background: url("../img/gnav1.png") no-repeat;
    background-size: cover;
  }
  &_invitation {
    background: url("../img/gnav2.png") no-repeat;
    background-size: cover;
  }
  &_reception {
    background: url("../img/gnav3.png") no-repeat;
    background-size: cover;
  }
  &_seatplace {
    background: url("../img/gnav4.png") no-repeat;
    background-size: cover;
  }
  &_poti {
    background: url("../img/gnav5.png") no-repeat;
    background-size: cover;
  }
  &_spare {
    background: url("../img/gnav6.png") no-repeat;
    background-size: cover;
  }
}

//ドロップシャドウ
.gnav li {
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
  //filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.6));
}
