@charset "UTF-8";

body {
  font-family: "メイリオ", "Meiryo", "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif;
}

.obon {
  background: #eeeeee;
  padding: 20px;
  &_red {
    color: red;
    margin-bottom: 4px;
  }
  &_head {
    font-size: 18px;
    color: red;
    margin-bottom: 15px;
  }
  &_mr13 {
    margin-bottom: 13px;
  }
}

.wrapper {
  border-top: 14px solid #FAEFEE;
  padding-top: 0px;
  @include mq-tb {
    padding-top: 10px;
  }
}

.header {
  margin-bottom: 4px;
  &_main {
    width: 450px;
    margin-top: 20px;
    background: url("../img/logo.png") no-repeat;
    background-size: 80%;
    background-size: contain;
    margin-bottom: 3px;
    @include mq-tb {
      background-size: 100%;
      margin-bottom: 0;
  }
  }
  &_mainText {
    width: 190px;
    padding: 5px 5px 20px 5px;
    margin-top: -10px;
    margin-left: auto;
    margin-right: 20px;
    font-size: 12px;
    line-height: 1.0;
    @include mq-tb {
      width: 250px;
      padding: 5px 0 10px 5px;
      margin-left: auto;
      margin-right: 0;
      font-size: 12px;
      line-height: 1.4;
    }
  }
  &_head {
    margin-bottom: 6px;
    font-weight: bold;
    & span {
      font-size: 16px;
      color: red;
      @include mq-tb {
        font-size: 20px;
      }
    }
  }
  &_sub {
    flex: 1 1 45%;
    text-align: center;
    line-height: 1.2;
    }
    & .menu_item {
      padding: 0;
    }
    &_note {
      margin-top: 6px;
      text-align: right;
      font-size: 12px;
      @include mq-tb {
      font-size: 16px;
    }
  }
  &_note span {
    color: red;
  }
  &_menu {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    & li:nth-child(2)  {
      display: none;
    }
    & li {
      width: 33%;
    }
    @include mq-tb {
      font-size: 16px;
      & li:nth-child(2), & li:nth-child(3) {
        display: block;
      }
    }
    &_item {
      padding: 5px 10px;
      @include mq-tb {
        padding: 5px 5px;
      }
    }
  }
}
